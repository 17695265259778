<template>
  <div class="c_image_info_overlay">
    <slot></slot>
    <div
      class="c_box--mask c_image_info_overlay__overlay"
      :class="['c_image_info_overlay__overlay--pos--' + textPosition, onHover ? 'c_image_info_overlay--on_hover' : '']"
    >
      <p class="c_image_info_overlay__overlay__header c_header c_header--5 c_text--graphik_bold" v-if="header">
        {{ header }}
      </p>
      <div class="c_image_info_overlay__overlay__content" v-if="textLeft || textRight">
        <p
          class="c_image_info_overlay__overlay__content__text c_image_info_overlay__overlay__content__text--left"
          v-if="textLeft"
        >
          {{ textLeft }}
        </p>
        <p
          class="c_image_info_overlay__overlay__content__text c_image_info_overlay__overlay__content__text--right"
          v-if="textRight"
        >
          {{ textRight }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    header: String,
    textLeft: String,
    textRight: String,
    textPosition: {
      type: String,
      default: 'bottom',
    },
    onHover: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.c_image_info_overlay {
  $self: &;
  --info-image-overlay--padding: #{px_to_rem(28)};
  position: relative;
  display: inline-block;
  @at-root #{$self}__overlay {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    text-align: left;
    padding: var(--info-image-overlay--padding);
    transition: opacity 0.5s ease;
    background: rgba($color_black_raw, 0.4);
    color: $color_white;
    box-sizing: border-box;
    border-radius: 15px;
    @at-root #{$self}--on_hover {
      opacity: 0;
    }
    @at-root #{&}--pos--top {
      justify-content: flex-start;
    }
    @at-root #{&}--pos--bottom {
      justify-content: flex-end;
    }
    @at-root #{&}__header {
      padding-bottom: px_to_rem(8);
    }
    @at-root #{&}__content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin: 0 px_to_rem(-7);
      overflow-y: auto;
      @at-root #{&}__text {
        color: $color_gray_191;
        margin: 0 px_to_rem(7);
        @at-root #{&}--left {
          flex: 0 1 auto;
          text-align: left;
        }
        @at-root #{&}--right {
          flex: 0 1 auto;
          text-align: right;
        }
      }
    }
  }
  &:hover,
  &:focus {
    #{$self}__overlay {
      opacity: 1;
      border-radius: 15px;
    }
  }
}
</style>
