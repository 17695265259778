<template>
  <div class="c_lp_text_card_slider">
    <LPText
      class="c_lp_text_card_slider__intro"
      :eyebrow="eyebrow"
      :text="text"
      :subheader="header"
      :button-link-field="buttonLinkField"
      :text-alignment="textAlignment"
    ></LPText>
    <Slider
      ref="textCardSlider"
      class="c_lp_text_card_slider__slider"
      :show-indicators="perSlideCount < cards.length"
      :arrows="perSlideCount >= cards.length ? 'hidden' : $mq !== 'lg' ? 'overlay' : 'outside'"
      :main-height="cardType === 'background' ? '400px' : '-webkit-fill-available'"
    >
      <template #slides>
        <div class="c_lp_text_card_slider__slide" v-for="count in slideCount" :key="count">
          <div
            class="c_lp_text_card_slider__slide__item"
            :class="'c_lp_text_card_slider__slide__item--' + perSlideCount"
            v-for="(card, index) in cards.slice(perSlideCount * (count - 1), perSlideCount * count)"
            :key="index"
          >
            <Card
              v-if="cardType === 'default'"
              :href="parseLinkField(card.linkField, 'superTable').href"
              :title="card.header ? card.header : ''"
              :description="card.descriptor"
              :disable-mobile-view="true"
              :image-full-width="true"
              :image-options="card.image"
              :link-label="parseLinkField(card.linkField, 'superTable').labelText"
              :aria-label="parseLinkField(card.linkField, 'superTable').ariaLabel"
              :show-text-button="true"
              text-alignment="center"
              class="c_lp_text_card_slider__slide__item__default"
            ></Card>
            <CardMinimal
              class="c_lp_text_card_slider__slide__item__minimal"
              :image="card.image"
              :text="card.descriptor"
              :header="card.header"
              :button="card.linkField"
              v-else-if="cardType === 'minimal'"
            >
            </CardMinimal>
            <Button
              class="c_lp_text_card_slider__slide__item__background"
              :is="
                parseLinkField(card.linkField, 'superTable').valid && parseLinkField(card.linkField, 'superTable').href
                  ? 'Button'
                  : 'div'
              "
              :href="
                parseLinkField(card.linkField, 'superTable').valid
                  ? parseLinkField(card.linkField, 'superTable').href
                  : null
              "
              :aria-label="
                parseLinkField(card.linkField, 'superTable').valid
                  ? parseLinkField(card.linkField, 'superTable').ariaLabel
                  : null
              "
              :new-window="
                parseLinkField(card.linkField, 'superTable').valid
                  ? parseLinkField(card.linkField, 'superTable').newWindow
                  : null
              "
              reset
              unstyle
              v-else
            >
              <ImageInfoOverlay
                :header="card.header"
                :text-left="card.descriptor"
                :text-right="card.secondaryDescriptor"
                :on-hover="false"
              >
                <MediaImage v-if="card.image" background :alt="card.image.alt" :sources="card.image.sources" />
              </ImageInfoOverlay>
            </Button>
          </div>
        </div>
      </template>
    </Slider>
  </div>
</template>

<script>
import { parseLinkField } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import Card from 'Components/card/Card.vue';
import CardMinimal from 'Components/card/CardMinimal.vue';
import ImageInfoOverlay from 'Components/image_info_overlay/ImageInfoOverlay.vue';
import LPText from 'Components/lp_text/LPText.vue';
import Slider from 'Components/slider/Slider.vue';
import MediaImage from 'Components/image/MediaImage.vue';

export default {
  components: {
    Button,
    Card,
    CardMinimal,
    ImageInfoOverlay,
    LPText,
    MediaImage,
    Slider,
  },
  data() {
    return {};
  },
  props: {
    buttonLinkField: Object,
    eyebrow: String,
    header: String,
    text: String,
    textAlignment: {
      type: String,
      default: 'left',
    },
    cards: Array,
    cardType: String,
  },
  computed: {
    slideCount() {
      if (this.$mq === 'lg') {
        // log('ceil mq', Math.ceil(this.cards.length / 3));
        return Math.ceil(this.cards.length / 3);
      } else if (this.$mq === 'md') {
        // log('ceil mq', Math.ceil(this.cards.length / 2));
        return Math.ceil(this.cards.length / 2);
      } else {
        // log('ceil mq', this.cards.length);
        return this.cards.length;
      }
    },
    perSlideCount() {
      let returnPerSlide = 1;
      if (this.$mq === 'lg') {
        returnPerSlide = 3;
      } else if (this.$mq === 'md') {
        returnPerSlide = 2;
      } else {
        returnPerSlide = 1;
      }
      this.$nextTick(() => {
        this.reload();
      });
      return returnPerSlide;
    },
  },
  methods: {
    parseLinkField,
    reload() {
      if (this.$refs?.textCardSlider) {
        this.$refs.textCardSlider.reloadMain();
      }
    },
  },
};
</script>

<style lang="scss">
.c_lp_text_card_slider {
  $self: &;
  #{$self}__intro {
    padding-bottom: px_to_rem(30);
  }
  #{$self}__slider {
    padding-bottom: px_to_rem(30);
    .agile__list {
      overflow: visible;
    }
    .agile__dots {
      bottom: px_to_rem(-30);
    }
  }
  #{$self}__slide {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: px_to_rem(10) 0;
    @at-root #{&}__item {
      flex: 0 1 100%;
      max-width: 100%;
      box-sizing: border-box;

      .c_card {
        --card-image-height: #{px_to_rem(180)};
      }
      .c_image_info_overlay {
        width: 100%;
        height: 100%;
      }
      @include mq($mq_sm_to_md) {
        padding: 0 px_to_rem(7);
        &:first-of-type {
          padding-left: 0;
          padding-right: px_to_rem(14);
        }
        &:last-of-type {
          padding-left: px_to_rem(14);
          padding-right: 0;
        }
      }
      @at-root #{&}--2 {
        flex: 0 1 50%;
      }
      @at-root #{&}--3 {
        flex: 0 1 33.33%;
      }

      @at-root #{&}__minimal {
        height: 100%;
      }
      @at-root #{&}__background {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
