var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_lp_text_card_slider"},[_c('LPText',{staticClass:"c_lp_text_card_slider__intro",attrs:{"eyebrow":_vm.eyebrow,"text":_vm.text,"subheader":_vm.header,"button-link-field":_vm.buttonLinkField,"text-alignment":_vm.textAlignment}}),_vm._v(" "),_c('Slider',{ref:"textCardSlider",staticClass:"c_lp_text_card_slider__slider",attrs:{"show-indicators":_vm.perSlideCount < _vm.cards.length,"arrows":_vm.perSlideCount >= _vm.cards.length ? 'hidden' : _vm.$mq !== 'lg' ? 'overlay' : 'outside',"main-height":_vm.cardType === 'background' ? '400px' : '-webkit-fill-available'},scopedSlots:_vm._u([{key:"slides",fn:function(){return _vm._l((_vm.slideCount),function(count){return _c('div',{key:count,staticClass:"c_lp_text_card_slider__slide"},_vm._l((_vm.cards.slice(_vm.perSlideCount * (count - 1), _vm.perSlideCount * count)),function(card,index){return _c('div',{key:index,staticClass:"c_lp_text_card_slider__slide__item",class:'c_lp_text_card_slider__slide__item--' + _vm.perSlideCount},[(_vm.cardType === 'default')?_c('Card',{staticClass:"c_lp_text_card_slider__slide__item__default",attrs:{"href":_vm.parseLinkField(card.linkField, 'superTable').href,"title":card.header ? card.header : '',"description":card.descriptor,"disable-mobile-view":true,"image-full-width":true,"image-options":card.image,"link-label":_vm.parseLinkField(card.linkField, 'superTable').labelText,"aria-label":_vm.parseLinkField(card.linkField, 'superTable').ariaLabel,"show-text-button":true,"text-alignment":"center"}}):(_vm.cardType === 'minimal')?_c('CardMinimal',{staticClass:"c_lp_text_card_slider__slide__item__minimal",attrs:{"image":card.image,"text":card.descriptor,"header":card.header,"button":card.linkField}}):_c(_vm.parseLinkField(card.linkField, 'superTable').valid && _vm.parseLinkField(card.linkField, 'superTable').href
                ? 'Button'
                : 'div',{tag:"Button",staticClass:"c_lp_text_card_slider__slide__item__background",attrs:{"href":_vm.parseLinkField(card.linkField, 'superTable').valid
                ? _vm.parseLinkField(card.linkField, 'superTable').href
                : null,"aria-label":_vm.parseLinkField(card.linkField, 'superTable').valid
                ? _vm.parseLinkField(card.linkField, 'superTable').ariaLabel
                : null,"new-window":_vm.parseLinkField(card.linkField, 'superTable').valid
                ? _vm.parseLinkField(card.linkField, 'superTable').newWindow
                : null,"reset":"","unstyle":""}},[_c('ImageInfoOverlay',{attrs:{"header":card.header,"text-left":card.descriptor,"text-right":card.secondaryDescriptor,"on-hover":false}},[(card.image)?_c('MediaImage',{attrs:{"background":"","alt":card.image.alt,"sources":card.image.sources}}):_vm._e()],1)],1)],1)}),0)})},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }