<template>
  <div
    class="c_card_minimal"
    :class="'c_card_minimal--' + size"
    :is="parseLinkField(button, 'superTable').valid ? 'LinkField' : 'div'"
    :super-table-field="button"
    :button-attrs="{ reset: true, unstyle: true }"
  >
    <ImageInfoOverlay
      :is="parseLinkField(button, 'superTable').valid && image && image.length ? 'ImageInfoOverlay' : 'div'"
      class="c_card_minimal__image__overlay"
    >
      <div class="c_card_minimal__image" v-if="image">
        <MediaImage background :alt="image.alt" :sources="image.sources" />
        <span class="c_card_minimal__image__descriptor c_box--pos--absolute" v-if="onImageDescriptor">{{
          onImageDescriptor
        }}</span>
      </div>
    </ImageInfoOverlay>
    <div class="c_card_minimal__content">
      <p
        class="c_card_minimal__content__header c_header c_text--graphik_bold"
        :class="size == 'large' ? 'c_header--5' : 'c_header--4'"
        v-if="header"
      >
        {{ header }}
      </p>
      <div class="c_card_minimal__content__text c_text" v-if="text" v-html="text"></div>
    </div>
  </div>
</template>

<script>
import { parseLinkField } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import ImageInfoOverlay from 'Components/image_info_overlay/ImageInfoOverlay.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import MediaImage from 'Components/image/MediaImage.vue';

export default {
  components: {
    Button,
    ImageInfoOverlay,
    LinkField,
    MediaImage,
  },
  data() {
    return {};
  },
  props: {
    image: Object,
    size: {
      type: String,
      default: 'small',
    },
    header: String,
    button: Object,
    text: String,
    onImageDescriptor: String,
  },
  computed: {},
  methods: {
    parseLinkField,
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
.c_card_minimal {
  $self: &;
  display: flex;
  flex-flow: column nowrap;
  text-decoration: none;
  --card-minimal--image-height: #{px_to_rem(400)};
  --card-minimal--content-padding: 0;
  @at-root #{$self}--large {
    --card-minimal--image-height: #{px_to_rem(400)};
    --card-minimal--content-padding: #{px_to_rem(25)};
    @include mq($mq_grid_horizontal_1) {
      --card-minimal--image-height: #{px_to_rem(550)};
    }
  }
  @at-root #{$self}__image {
    height: 100%;
    position: relative;
    @at-root #{&}__overlay {
      flex: 0 1 var(--card-minimal--image-height);
    }
    @at-root #{&}__descriptor {
      display: block;
      z-index: 5;
      width: 100%;
      top: 0;
      left: 0;
      padding: var(--card-minimal--content-padding);
      color: $color_gray_112;
      box-sizing: border-box;
    }
  }

  @at-root #{$self}__content {
    flex: 0 0 auto;
    padding: var(--card-minimal--content-padding);
    padding-top: px_to_rem(25);
    text-align: left;
    white-space: initial;
    line-height: px_to_rem(20);
    @at-root #{&}__header {
      a & {
        color: $color_black;
        text-decoration: none;
        @at-root .c_box--black & {
          color: $color_white;
        }
      }
      a:hover &,
      a:focus & {
        color: $color_red;
      }
    }
    @at-root #{&}__text {
      color: $color_black;
      line-height: px_to_rem(20);
      font-size: px_to_rem(14);
      padding-top: px_to_rem(5);
      @at-root .c_box--black & {
        color: $color_white;
      }
      a & {
        color: $color_black;
        font-size: px_to_rem(14);
        padding-top: px_to_rem(5);
        text-decoration: none;
        @at-root .c_box--black & {
          color: $color_white;
        }
      }
    }
  }
}
</style>
